import Container from 'common/src/components/UI/Container';
import { DataContext } from 'common/src/contexts/DataContext'
import React from 'react';

const TextContentSection = ({ children }) => {
  const { data } = React.useContext(DataContext)
  
  return (
    <Container>
      <div style={{ minHeight: '400px', marginTop: '200px' }}>
        {children}
      </div>
    </Container>
  )
};

export default TextContentSection
