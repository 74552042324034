import { ContentWrapper, GlobalStyle } from '../containers/Lang4U/lang4u.style';
import React, { Fragment } from 'react';

import { DataContext } from 'common/src/contexts/DataContext'
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Footer from '../containers/Lang4U/Footer';
import Heading from 'common/src/components/Heading';
import Navbar from '../containers/Lang4U/Navbar';
import { ResetCSS } from 'common/src/assets/css/style';
import { RichText } from 'prismic-reactjs';
import SEO from '../components/seo';
import Sticky from 'react-stickynode';
import Text from 'common/src/components/Text';
import TextContentSection from '../containers/Lang4U/TextContent';
import { ThemeProvider } from 'styled-components';
import { lang4uTheme } from 'common/src/theme/lang4u';

export const TextPage = result => {
  const edge = result.prismic.allLanding_pages.edges.slice(0, 1).pop();
  if (!edge) {
    return null;
  }
  const data = edge.node;
  if (!data) {
    return null;
  }

  return (
    <DataContext.Provider value={{ data }}>
      <ThemeProvider theme={lang4uTheme}>
        <Fragment>
          <ResetCSS />
          <GlobalStyle />
          <SEO
            title={RichText.asText(result.prismic.allPages.edges[0].node.title)}
            description={RichText.asText(data.document_seo_description)}
            image={data.hero_image.url}
            imageDimensions={data.hero_image.dimensions}
          />


          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active-mock" className="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <TextContentSection>
              <Heading content={RichText.asText(result.prismic.allPages.edges[0].node.title)} />
              <RichText
                as="div"
                render={result.prismic.allPages.edges[0].node.content}
                Component={Text}
              />
            </TextContentSection>
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    </DataContext.Provider>
  );
};
